<template> 
  <div class="detail-container">
    <el-backtop :bottom="60"></el-backtop>
    <div>
      <el-steps :active="formatStepStatus(order.orderStatus)" finish-status="success" align-center>
        <el-step title="提交订单" :description="order.createTime"/>
        <el-step title="支付订单" :description="order.paymentTime"/>
        <el-step title="平台发货" :description="order.sendTime"/>
        <el-step title="确认收货" :description="order.confirmReceiptTime"/>
      </el-steps>
    </div>
    <el-card shadow="never" style="margin-top: 15px">
      <div class="operate-container">
        <i class="el-icon-warning " style="margin-left: 20px;color:red"/>
        <span style="color:#FA8258">当前订单状态：{{order.orderStatus | formatStatus}}</span>
        <div class="operate-button-container">
          <el-button size="mini" @click="back">返回上一级</el-button>
        </div>
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker"/>
        <span class="font-small">基本信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="6" class="table-cell-title">订单编号</el-col>
          <el-col :span="3" class="table-cell-title">下单用户</el-col>
          <el-col :span="3" class="table-cell-title">支付方式</el-col>
          <el-col :span="6" class="table-cell-title">快递公司</el-col>
          <el-col :span="6" class="table-cell-title">快递单号</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell">{{order.orderCode}}</el-col>
          <el-col :span="3" class="table-cell">{{order.userName}}</el-col>
          <el-col :span="3" class="table-cell">{{order.payType | formatPayType}}</el-col>
          <el-col :span="6" class="table-cell">{{order.trackingCompany}}</el-col>
          <el-col :span="6" class="table-cell">{{order.trackingNumber}}</el-col>
        </el-row>
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker" style="color: #606266"/>
        <span class="font-small">收货人信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="8" class="table-cell-title">收货人姓名</el-col>
          <el-col :span="8" class="table-cell-title">手机号码</el-col>
          <el-col :span="8" class="table-cell-title">收货地址</el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="table-cell">{{order.receiverName}}</el-col>
          <el-col :span="8" class="table-cell">{{order.receiverPhone}}</el-col>
          <el-col :span="8" class="table-cell">{{order | formatAddress}}</el-col>
        </el-row>
      </div>
      <div style="margin-top: 20px">
        <svg-icon icon-class="marker" style="color: #606266"/>
        <span class="font-small">商品信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="4" class="table-cell-title">商品图片</el-col>
          <el-col :span="4" class="table-cell-title">商品名称</el-col>
          <el-col :span="4" class="table-cell-title" >单价</el-col>
          <el-col :span="4" class="table-cell-title">单个所需要积分</el-col>
          <el-col :span="2" class="table-cell-title">数量</el-col>
          <el-col :span="3" class="table-cell-title">积分合计</el-col>
          <el-col :span="3" class="table-cell-title">价格合计</el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell-self">
            <img :src="baseUrl+order.productPicture" style="height: 80px"/>
          </el-col>
          <el-col :span="4" class="table-cell-self">
            <span>{{order.productName}}</span>
          </el-col>
          <el-col :span="4" class="table-cell-self">
            <span><span v-if="order.price">￥</span>{{order.price|formatNullNumber}}</span>
          </el-col>
          <el-col :span="4" class="table-cell-self">
            <span>{{order.scoreNum|formatNullNumber}}</span>
          </el-col>
          <el-col :span="2" class="table-cell-self">
            <span>{{order.exchangeNum}}</span>
          </el-col>
          <el-col :span="3" class="table-cell-self">
            <span>{{order.scoreNum*order.exchangeNum}}</span>
          </el-col>
          <el-col :span="3" class="table-cell-self">
            <span>￥{{order.price*order.exchangeNum}}</span>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 60px">
        <svg-icon icon-class="marker"/>
        <span class="font-small">费用信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="6" class="table-cell-title">商品合计</el-col>
          <el-col :span="6" class="table-cell-title">运费</el-col>
          <el-col :span="6" class="table-cell-title">积分消费</el-col>
          <el-col :span="6" class="table-cell-title">订单总金额</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="table-cell">￥{{order.totalPrice|formatNullNumber}}</el-col>
          <el-col :span="6" class="table-cell">￥{{order.freightAmount|formatNullNumber}}</el-col>
          <el-col :span="6" class="table-cell">{{order.totalScoreNum|formatNullNumber}}</el-col>
          <el-col :span="6" class="table-cell">
            <span style="color:red">￥{{order.totalPrice+order.freightAmount}}</span>
          </el-col>
        </el-row>
      </div>
      <!--      <div style="margin-top: 20px">-->
      <!--        <svg-icon icon-class="marker" style="color: #606266"/>-->
      <!--        <span class="font-small">操作信息</span>-->
      <!--      </div>-->
      <!--      <el-table style="margin-top: 20px;width: 100%"-->
      <!--                ref="orderHistoryTable"-->
      <!--                :data="order.historyList" border>-->
      <!--        <el-table-column label="操作者" width="120" align="center">-->
      <!--          <template slot-scope="scope">-->
      <!--            {{scope.row.createBy}}-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column label="操作时间" width="160" align="center">-->
      <!--          <template slot-scope="scope">-->
      <!--            {{formatTime(scope.row.createTime)}}-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column label="订单状态" width="120" align="center">-->
      <!--          <template slot-scope="scope">-->
      <!--            {{scope.row.orderStatus | formatStatus}}-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column label="付款状态" width="120" align="center">-->
      <!--          <template slot-scope="scope">-->
      <!--            {{scope.row.orderStatus | formatPayStatus}}-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column label="发货状态" width="120" align="center">-->
      <!--          <template slot-scope="scope">-->
      <!--            {{scope.row.orderStatus | formatDeliverStatus}}-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->
    </el-card>
  </div>
</template>

<script>
  import {getShopScoreOrder} from '@/api/shop/shopScoreOrder';

  export default {
    name: 'orderDetail',
    data() {
      return {
        id: null,
        order: {},
        baseUrl: process.env.VUE_APP_BASE_API
      }
    },
    created() {
      this.getOrderDetails();
    },
    filters: {
      /** 支付方式翻译 */
      formatPayType(value) {
        if (value === 1) {
          return '微信';
        } else if (value === 2) {
          return '支付宝';
        } else if (value === 3) {
          return '银行卡';
        } else {
          return '未支付';
        }
      },
      /** 地址格式化(省+市+区+详细地址) */
      formatAddress(order) {
        let provinceName=order.receiverProvinceName;
        let cityName=order.receiverCityName;
        let districtName=order.receiverDistrictName;
        let detailAddress=order.receiverDetailAddress;
        let address=[provinceName,cityName,districtName,detailAddress];
        address.forEach(function (item,index,arr) {
              if(item==null){
                arr.splice(index,1);
              }
        });
        return address.join(" ");
      },
      /** 订单状态翻译 */
      formatStatus(value) {
        if (value === 2) {
          return '待发货';
        } else if (value === 3) {
          return '已发货';
        } else if (value === 4) {
          return '已完成';
        } else if (value === 5) {
          return '已关闭';
        } else {
          return '待付款';
        }
      },
      formatNullNumber(value){
        if(value===null){
          return 0;
        }else{
          return value;
        }
      }
      // /** 支付状态翻译 */
      // formatPayStatus(value) {
      //   if (value === 1) {
      //     return '未支付';
      //   } else if (value === 5) {
      //     return '已退款';
      //   } else {
      //     return '已支付';
      //   }
      // },
      // /** 发货状态翻译 */
      // formatDeliverStatus(value) {
      //   if (value === 1 || value === 2) {
      //     return '未发货';
      //   } else if (value === 5) {
      //     return '';
      //   } else {
      //     return '已发货';
      //   }
      // },
    },
    methods: {
      /** 返回上一页并关闭当前页 */
      back() {
        this.$store.dispatch("tagsView/delView", this.$route);
        this.$router.go(-1);
      },
      /** 获得订单相关信息 */
      getOrderDetails() {
        let id = this.$route.query.id;
        getShopScoreOrder(id).then(response => {
          this.order = response.data;
        });
      },
      /** 格式化顶部订单状态显示 */
      formatStepStatus(value) {
        if (value === 2) {
          //待发货
          return 2;
        } else if (value === 3) {
          //已发货
          return 3;
        } else if (value === 4) {
          //已完成
          return 4;
        } else {
          //待付款、已关闭
          return 1;
        }
      },
    }
  }
</script>
<style scoped>
  .detail-container {
    width: 80%;
    padding: 20px 20px 20px 20px;
    margin: 20px auto;
  }

  .operate-container {
    background: #F2F6FC;
    height: 80px;
    margin: -20px -20px 0;
    line-height: 80px;
  }

  .operate-button-container {
    float: right;
    margin-right: 20px
  }

  .table-layout {
    margin-top: 20px;
    border-left: 1px solid #DCDFE6;
    border-top: 1px solid #DCDFE6;
  }

  .table-cell {
    height: 60px;
    line-height: 40px;
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    padding: 10px;
    font-size: 14px;
    color: #606266;
    text-align: center;
    overflow: hidden;
  }

  .table-cell-self {
    height: 110px;
    line-height: 40px;
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    padding: 30px;
    font-size: 14px;
    color: #606266;
    text-align: center;
    overflow: hidden;
  }

  .table-cell-title {
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    padding: 10px;
    background: #F2F6FC;
    text-align: center;
    font-size: 14px;
    color: #303133;
  }
</style>
