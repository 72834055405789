var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _c("el-backtop", { attrs: { bottom: 60 } }),
      _c(
        "div",
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: _vm.formatStepStatus(_vm.order.orderStatus),
                "finish-status": "success",
                "align-center": "",
              },
            },
            [
              _c("el-step", {
                attrs: { title: "提交订单", description: _vm.order.createTime },
              }),
              _c("el-step", {
                attrs: {
                  title: "支付订单",
                  description: _vm.order.paymentTime,
                },
              }),
              _c("el-step", {
                attrs: { title: "平台发货", description: _vm.order.sendTime },
              }),
              _c("el-step", {
                attrs: {
                  title: "确认收货",
                  description: _vm.order.confirmReceiptTime,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "15px" }, attrs: { shadow: "never" } },
        [
          _c("div", { staticClass: "operate-container" }, [
            _c("i", {
              staticClass: "el-icon-warning ",
              staticStyle: { "margin-left": "20px", color: "red" },
            }),
            _c("span", { staticStyle: { color: "#FA8258" } }, [
              _vm._v(
                "当前订单状态：" +
                  _vm._s(_vm._f("formatStatus")(_vm.order.orderStatus))
              ),
            ]),
            _c(
              "div",
              { staticClass: "operate-button-container" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.back } },
                  [_vm._v("返回上一级")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("svg-icon", { attrs: { "icon-class": "marker" } }),
              _c("span", { staticClass: "font-small" }, [_vm._v("基本信息")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-layout" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("订单编号")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 3 } },
                    [_vm._v("下单用户")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 3 } },
                    [_vm._v("支付方式")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("快递公司")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("快递单号")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [_vm._v(_vm._s(_vm.order.orderCode))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 3 } },
                    [_vm._v(_vm._s(_vm.order.userName))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 3 } },
                    [_vm._v(_vm._s(_vm._f("formatPayType")(_vm.order.payType)))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [_vm._v(_vm._s(_vm.order.trackingCompany))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [_vm._v(_vm._s(_vm.order.trackingNumber))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("svg-icon", {
                staticStyle: { color: "#606266" },
                attrs: { "icon-class": "marker" },
              }),
              _c("span", { staticClass: "font-small" }, [_vm._v("收货人信息")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-layout" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 8 } },
                    [_vm._v("收货人姓名")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 8 } },
                    [_vm._v("手机号码")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 8 } },
                    [_vm._v("收货地址")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 8 } },
                    [_vm._v(_vm._s(_vm.order.receiverName))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 8 } },
                    [_vm._v(_vm._s(_vm.order.receiverPhone))]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 8 } },
                    [_vm._v(_vm._s(_vm._f("formatAddress")(_vm.order)))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("svg-icon", {
                staticStyle: { color: "#606266" },
                attrs: { "icon-class": "marker" },
              }),
              _c("span", { staticClass: "font-small" }, [_vm._v("商品信息")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-layout" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("商品图片")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("商品名称")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("单价")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("单个所需要积分")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 2 } },
                    [_vm._v("数量")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 3 } },
                    [_vm._v("积分合计")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 3 } },
                    [_vm._v("价格合计")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 4 } },
                    [
                      _c("img", {
                        staticStyle: { height: "80px" },
                        attrs: { src: _vm.baseUrl + _vm.order.productPicture },
                      }),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 4 } },
                    [_c("span", [_vm._v(_vm._s(_vm.order.productName))])]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 4 } },
                    [
                      _c("span", [
                        _vm.order.price ? _c("span", [_vm._v("￥")]) : _vm._e(),
                        _vm._v(
                          _vm._s(_vm._f("formatNullNumber")(_vm.order.price))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 4 } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("formatNullNumber")(_vm.order.scoreNum))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 2 } },
                    [_c("span", [_vm._v(_vm._s(_vm.order.exchangeNum))])]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 3 } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.order.scoreNum * _vm.order.exchangeNum)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-self", attrs: { span: 3 } },
                    [
                      _c("span", [
                        _vm._v(
                          "￥" + _vm._s(_vm.order.price * _vm.order.exchangeNum)
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "60px" } },
            [
              _c("svg-icon", { attrs: { "icon-class": "marker" } }),
              _c("span", { staticClass: "font-small" }, [_vm._v("费用信息")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-layout" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("商品合计")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("运费")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("积分消费")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 6 } },
                    [_vm._v("订单总金额")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [
                      _vm._v(
                        "￥" +
                          _vm._s(
                            _vm._f("formatNullNumber")(_vm.order.totalPrice)
                          )
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [
                      _vm._v(
                        "￥" +
                          _vm._s(
                            _vm._f("formatNullNumber")(_vm.order.freightAmount)
                          )
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatNullNumber")(_vm.order.totalScoreNum)
                        )
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 6 } },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm.order.totalPrice + _vm.order.freightAmount
                            )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }