import request from '@/utils/request'

// 查询积分订单列表
export function listShopScoreOrder(query) {
  return request({
    url: '/shop/shopScoreOrder/list',
    method: 'get',
    params: query
  })
}

// 查询积分订单详细
export function getShopScoreOrder(id) {
  return request({
    url: '/shop/shopScoreOrder/' + id,
    method: 'get'
  })
}

// 新增积分订单
export function addShopScoreOrder(data) {
  return request({
    url: '/shop/shopScoreOrder',
    method: 'post',
    data: data
  })
}

// 修改积分订单
export function updateShopScoreOrder(data) {
  return request({
    url: '/shop/shopScoreOrder',
    method: 'put',
    data: data
  })
}

// 删除积分订单
export function delShopScoreOrder(id) {
  return request({
    url: '/shop/shopScoreOrder/' + id,
    method: 'delete'
  })
}

// 导出积分订单
export function exportShopScoreOrder(query) {
  return request({
    url: '/shop/shopScoreOrder/export',
    method: 'get',
    params: query
  })
}

// // 关闭单个积分订单
// export function closeShopScoreOrder(id,orderStatus) {
//   const data={
//     id,
//     orderStatus
//   }
//   return request({
//     url:'/shop/shopScoreOrder/changeShopOrderStatus',
//     method:'put',
//     data:data
//   })
// }

// 批量关闭积分订单
export function closeShopScoreOrderList(ids) {
  return request({
    url: '/shop/shopScoreOrder/changeShopOrderListStatus/' + ids,
    method: 'post',
  })
}

// 发货处理操作
export function deliveryOrder(data) {
  return request({
    url: '/shop/shopScoreOrder/delivery',
    method: 'put',
    data: data
  });
}
