<template>
  <el-image
    :preview-src-list="realSrcList"
    :src="`${realSrc}`"
    :style="`width:${realWidth};height:${realHeight};`"
    fit="cover"
  >
    <div class="image-slot" slot="error">
      <i class="el-icon-picture-outline"></i>
    </div>
  </el-image>
</template>

<script>
import {isExternal} from "@/utils/validate";

export default {
  name: "ImagePreview",
  props: {
    src: {
      type: String,
      default: ""
    },
    width: {
      type: [Number, String],
      default: ""
    },
    height: {
      type: [Number, String],
      default: ""
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API
    }
  },
  computed: {
    realSrc() {
      if (this.src) {
        let real_src = this.src.split(",")[0];
        if (isExternal(real_src)) {
          return real_src;
        }
        return this.baseURL + real_src;
      }
      return "";
    },
    realSrcList() {
      if (this.src) {
        let real_src_list = this.src.split(",");
        let srcList = [];
        real_src_list.forEach(item => {
          if (isExternal(item)) {
            return srcList.push(item);
          }
          return srcList.push(this.baseURL + item);
        });
        return srcList;
      }
      return [];
    },
    realWidth() {
      return typeof this.width == "string" ? this.width : `${this.width}px`;
    },
    realHeight() {
      return typeof this.height == "string" ? this.height : `${this.height}px`;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-image {
  border-radius: 5px;
  background-color: #ebeef5;
  box-shadow: 0 0 5px 1px #ccc;

  ::v-deep .el-image__inner {
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  ::v-deep .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #909399;
    font-size: 30px;
  }
}
</style>
